import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import decode from "jwt-decode";

const redirectionRoutes = ["/login", "/"];

const useAuth = () => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [user, setUser] = useState(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      setIsLoggedin(false);
      history.push("/login");
    } else {
      if (redirectionRoutes.includes(location.pathname)) {
        history.push("/dashboard/dashboard");
      }
      setIsLoggedin(true);

      const decodedToken = decode(token);
      setUser(decodedToken);
    }
  }, [history, location.pathname]);

  const login = (token) => {
    window.localStorage.setItem("token", token);
    setIsLoggedin(true);
    history.push("/dashboard/dashboard");
  };

  const logout = () => {
    window.localStorage.removeItem("token");
    setIsLoggedin(false);
    history.push("/login");
  };

  return { isLoggedin, user, login, logout };
};

export default useAuth;
