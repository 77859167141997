import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Context from "../shared/context/Context";
import Spinner from "../shared/ui/Spinner/Spinner";

const Login = lazy(() => import("../pages/Login/Login"));
const Dashboard = lazy(() => import("../shared/ui/MainLayout/MainLayout"));

const Routes = () => {
  const auth = useContext(Context);

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Switch>
          {!auth.isLoggedin && <Route path="/login" exact component={Login} />}
          {auth.isLoggedin && <Route path="/dashboard" component={Dashboard} />}
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
