import { ToastContainer } from "react-toastify";
import useAuth from "./shared/hooks/auth-hook";
import Context from "./shared/context/Context";

import Routes from "./routes/Routes";
import ScrollTop from "./shared/ui/Main/Main";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const App = () => {
  const { isLoggedin, user, login, logout } = useAuth();

  return (
    <Context.Provider
      value={{
        isLoggedin,
        user,
        login,
        logout,
      }}
    >
      <ScrollTop>
        <Routes />
        <ToastContainer
          hideProgressBar={true}
          toastClassName="toastify-element"
        />
      </ScrollTop>
    </Context.Provider>
  );
};

export default App;
